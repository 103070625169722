/**
 * Conventions:
 * For toggles, use toValue and fromValue with the value of toggle as a legible string
 */

export const web = {
	webVitals: "web_webVitals",
};

export const notFound = {
	webNotFound: "notFound_webNotFound",
	photo: "notFound_photo",
	gathering: "notFound_gathering",
	showcase: "notFound_showcase",
	general: "notFound_general",
	community: "notFound_community",
};

export const shareable = {
	download: {
		requested: "shareable_download_requested",
		completed: "shareable_download_completed",
	},
	instagramStory: {
		requested: "shareable_instagramStory_requested",
		completed: "shareable_instagramStory_completed",
	},
};

export const auth = {
	login: "auth_login",
	logout: "auth_logout",
};
export const onboarding = {
	continue: "onboarding_continue",
	backButtonPress: "onboarding_backButtonPress",
	complete: "onboarding_complete",
	jump: "onboarding_jump",
};
export const profilePicture = {
	upload: "profilePicture_upload",
	select: "profilePicture_select",
	noPermission: "profilePicture_noPermission",
};
export const contactPhoneNumber = {
	allowContacts: "contactPhoneNumber_allowContacts",
	denyContacts: "contactPhoneNumber_denyContacts",
	postPhoneNumber: "contactPhoneNumber_postPhoneNumber",
	postOtp: "contactPhoneNumber_postOtp",
	skip: "contactPhoneNumber_skip",
};
export const navigate = {
	newScreen: "navigate_newScreen",
};
export const thread = {
	reportEvent: "thread_reportEvent",
	deleteEvent: "thread_deleteEvent",
	createPost: "thread_createPost",
};
export const notification = {
	click: "notification_click",
	inAppReceive: "notification_inAppReceive",
};
export const profile = {
	reportUser: "profile_reportUser",
	pageView: "profile_pageView",
};
export const appStateChange = {
	active: "appStateChange_active",
	background: "appStateChange_background",
	inactive: "appStateChange_inactive",
};
export const share = {
	menuOpen: "share_menuOpen",
	share: "share_share",
	instagramDM: "share_instagramDM",
	instagramStory: "share_instagramStory",
	sms: "share_sms",
	clipboard: "share_clipboard",
	download: "share_download",
};
export const permission = {
	allow: "permission_allow",
	deny: "permission_deny",
};
export const link = {
	click: "link_click",
};
export const settings = {
	changeNamePress: "settings_changeNamePress",
	changeInstagramPress: "settings_changeInstagramPress",
	changeSnapchatPress: "settings_changeSnapchatPress",
	changeSchoolPress: "settings_changeSchoolPress",
	permissionsPress: "settings_permissionsPress",
	rateUsPress: "settings_rateUsPress",
	clearCache: "settings_clearCache",
	feedback: {
		initiate: "settings_feedback_initiate",
		confirm: "settings_feedback_confirm",
		cancel: "settings_feedback_cancel",
	},
	termsPress: "settings_termsPress",
	policyPress: "settings_policyPress",
	reviewRequest: "settings_reviewRequest",
	reportBug: {
		initiate: "settings_reportBug_initiate",
		confirm: "settings_reportBug_confirm",
		cancel: "settings_reportBug_cancel",
	},
	logoutPress: "settings_logoutPress",
	deleteAccount: {
		initiate: "settings_deleteAccount_initiate",
		confirm: "settings_deleteAccount_confirm",
		cancel: "settings_deleteAccount_cancel",
	},
	developerSettingsPress: "settings_developerSettingsPress",
	developerSettings: {
		createKey: "settings_developerSettings_createKey",
		keyDelete: {
			initiate: "settings_developerSettings_keyDelete_initiate",
			confirm: "settings_developerSettings_keyDelete_confirm",
			cancel: "settings_developerSettings_keyDelete_cancel",
		},
	},
};
export const poll = {
	navigateToPoll: "poll_navigateToPoll",
	navigateToInteraction: "poll_navigateToInteraction",
	next: "poll_next",
	skip: "poll_skip",
	answer: "poll_answer",
	outOfPolls: "poll_outOfPolls",
};
export const integration = {
	badgeClick: "integration_badgeClick",
};
export const modal = {
	presentModal: "modal_presentModal",
};

export const download = {
	toastOpen: "download_toastOpen",
	toastDismiss: "download_toastDismiss",
	toastClose: "download_toastClose",
	dataDownloadComplete: "download_dataDownloadComplete",
};

export const upload = {
	toastOpen: "upload_toastOpen",
	toastDismiss: "upload_toastDismiss",
	toastClose: "upload_toastClose",
	dataUploadComplete: "upload_dataUploadComplete",
	clearCache: "upload_clearCache",
	confirmationBatch: "upload_confirmationBatch",
};
export const photo = {
	report: {
		initiate: "photo_report_initiate",
		confirm: "photo_report_confirm",
		cancel: "photo_report_cancel",
	},
	delete: {
		initiate: "photo_delete_initiate",
		confirm: "photo_delete_confirm",
		cancel: "photo_delete_cancel",
	},
	uploadStart: "photo_uploadStart",
	attemptUploadUnsupportedFile: "photo_attemptUploadUnsupportedFile",
	tagFace: {
		initiate: "photo_tagFace_initiate",
		complete: "photo_tagFace_complete",
		cancel: "photo_tagFace_cancel",
	},
	tagOpen: "photo_tagOpen",
	shareAction: "photo_shareAction",
	recapShareableClick: "photo_recapShareableClick",
	reaction: {
		menuOpenClick: "photo_reaction_menuOpenClick",
		addReaction: "photo_reaction_addReaction",
		removeReaction: "photo_reaction_removeReaction",
	},
	hideDepicted: {
		initiate: "photo_hideDepicted_initiate",
		confirm: "photo_hideDepicted_confirm",
		cancel: "photo_hideDepicted_cancel",
	},
};

export const gathering = {
	missing: {
		facialRecognition: {
			prompt: "gathering_missing_facialRecognition_prompt",
			ignore: "gathering_missing_facialRecognition_ignore",
			settings: "gathering_missing_facialRecognition_settings",
		},
		facePfp: {
			prompt: "gathering_missing_facePfp_prompt",
			ignore: "gathering_missing_facePfp_ignore",
			settings: "gathering_missing_facePfp_settings",
		},
	},
	previewAlbumPressed: "gathering_previewAlbumPressed",
	photoDownload: "gathering_photoDownload",
	photoCapture: "gathering_photoCapture",
	edit: "gathering_edit",
	create: "gathering_create",
	photoExpand: "gathering_photoExpand",
	selectThumbnail: "gathering_selectThumbnail",
	deselectThumbnail: "gathering_deselectThumbnail",
	enableThumbnailEdit: "gathering_enableThumbnailEdit",
	changeFilterMode: "gathering_changeFilterMode",
	selectPhoto: "gathering_selectPhoto",
	deselectPhoto: "gathering_deselectPhoto",
	selectLimit: "gathering_selectLimit",
	changeSelectMode: "gathering_changeSelectMode",
	changeEditMode: "gathering_changeEditMode",
	changeViewMode: "gathering_changeViewMode",
	previewReport: {
		initiate: "gathering_previewReport_initiate",
		confirm: "gathering_previewReport_confirm",
		cancel: "gathering_previewReport_cancel",
	},
	previewLoaded: "gathering_previewLoaded",
	previewSeeViewersPressed: "gathering_previewSeeViewersPressed",
	previewPhotoDetailPressed: "gathering_previewPhotoDetailPressed",
	shareableAction: {
		instagram: "gathering_shareableAction_instagram",
		download: "gathering_shareableAction_download",
	},
	inlineCreateClick: "gathering_inlineCreateClick",

	cancelThumbnailMode: "gathering_cancelThumbnailMode",
	completeThumbnailMode: "gathering_completeThumbnailMode",

	startImageHover: "gathering_startImageHover",
	endImageHover: "gathering_endImageHover",

	deletePhotos: {
		initiate: "gathering_deletePhotos_initiate",
		confirm: "gathering_deletePhotos_confirm",
		cancel: "gathering_deletePhotos_cancel",
	},
	joinGatheringSuccess: "gathering_joinGatheringSuccess",

	editDoneButtonPressed: "gathering_editDoneButtonPressed",
	editPrivacyButtonPressed: "gathering_editPrivacyButtonPressed",
	createCancel: {
		initiate: "gathering_createCancel_initiate",
		delete: "gathering_createCancel_delete",
		saveDraft: "gathering_createCancel_saveDraft",
		cancel: "gathering_createCancel_cancel",
	},
	createButtonPressed: "gathering_createButtonPressed",

	selectButtonPressed: "gathering_selectButtonPressed",
	selectCancelButtonPressed: "gathering_selectCancelButtonPressed",

	uploadPhotosButtonPressed: "gathering_uploadPhotosButtonPressed",
	noPhotosButtonPressed: "gathering_noPhotosButtonPressed",
	filterButtonPressed: "gathering_filterButtonPressed",
	seeViewersPressed: "gathering_seeViewersPressed",
	shareAlbumPressed: "gathering_shareAlbumPressed",
	recapSharePressed: "gathering_recapSharePressed",
	createGratitudeComment: "gathering_createGratitudeComment",
	otherUserFilterToggle: "gathering_otherUserFilterToggle",
	viewersCopyInvite: "gathering_viewersCopyInvite",
	options: {
		adminPrivacyPress: "gathering_options_adminPrivacyPress",
		participantPrivacyPress: "gathering_options_participantPrivacyPress",
		adminGratitudePress: "gathering_options_adminGratitudePress",
		participantGratitudePress: "gathering_options_participantGratitudePress",
		hiddenPress: "gathering_options_hiddenPress",
		recapSharePress: "gathering_options_recapSharePress",
		editAlbumPress: "gathering_options_editAlbumPress",
		openOptions: "gathering_options_openOptions",
		requestWristbandsPress: "gathering_options_requestWristbandsPress",
		filterPress: "gathering_options_filterPress",
		seeViewersPress: "gathering_options_seeViewersPress",
		shareAlbumPress: "gathering_options_shareAlbumPress",
		uploadPhotosPress: "gathering_options_uploadPhotosPress",
		logoutPress: "gathering_options_logoutPress",
		reportAlbum: {
			initiate: "gathering_options_reportAlbum_initiate",
			confirm: "gathering_options_reportAlbum_confirm",
			cancel: "gathering_options_reportAlbum_cancel",
		},
		deleteAlbum: {
			initiate: "gathering_options_deleteAlbum_initiate",
			confirm: "gathering_options_deleteAlbum_confirm",
			cancel: "gathering_options_deleteAlbum_cancel",
		},
		leaveAlbum: {
			initiate: "gathering_options_leaveAlbum_initiate",
			confirm: "gathering_options_leaveAlbum_confirm",
			cancel: "gathering_options_leaveAlbum_cancel",
		},
	},
	photoModeration: {
		hide: "gathering_photoModeration_hide",
		unhide: "gathering_photoModeration_unhide",
	},
	viewerOptions: {
		removeUser: {
			initiate: "gathering_viewerOptions_removeUser_initiate",
			confirm: "gathering_viewerOptions_removeUser_confirm",
			cancel: "gathering_viewerOptions_removeUser_cancel",
		},
		demoteAdmin: {
			initiate: "gathering_viewerOptions_demoteAdmin_initiate",
			confirm: "gathering_viewerOptions_demoteAdmin_confirm",
			cancel: "gathering_viewerOptions_demoteAdmin_cancel",
		},
		promoteAdmin: "gathering_viewerOptions_promoteAdmin",
	},
	gatheringNotReady: {
		prompt: "gathering_gatheringNotReady_prompt",
		okay: "gathering_gatheringNotReady_okay",
		reload: "gathering_gatheringNotReady_reload",
	},
	requestWristbandsModal: {
		cancel: "gathering_requestWristbandsModal_cancel",
		confirm: "gathering_requestWristbandsModal_confirm",
	},
	qrCode: {
		optionsInitiate: "gathering_qrCode_optionsInitiate",
		shareSuccess: "gathering_qrCode_shareSuccess",
	},
};
export const community = {
	create: "community_create",
	joinCommunitySuccess: "community_joinCommunitySuccess",
	joinCommunityRequested: "community_joinCommunityRequested",
	editDoneButtonPressed: "community_editDoneButtonPressed",
	createButtonPressed: "community_createButtonPressed",
	createCancel: {
		cancel: "community_createCancel_cancel",
		delete: "community_createCancel_delete",
		saveDraft: "community_createCancel_saveDraft",
	},
	emptyState: {
		addGatheringPress: "community_emptyState_addGatheringPress",
	},
	addGatheringPress: "community_addGatheringPress",
	addGatheringMenu: {
		addGatheringPress: "community_addGatheringMenu_addGatheringPress",
	},
	gathering: {
		remove: {
			initiate: "community_gathering_remove_initiate",
			confirm: "community_gathering_remove_confirm",
			cancel: "community_gathering_remove_cancel",
		},
		delete: {
			initiate: "community_gathering_delete_initiate",
			confirm: "community_gathering_delete_confirm",
			cancel: "community_gathering_delete_cancel",
		},
	},
	seeMembersPress: "community_seeMembersPress",
	shareCommunityPress: "community_shareCommunityPress",
	options: {
		openOptions: "community_options_openOptions",
		editCommunityPress: "community_options_editCommunityPress",
		addGatheringPress: "community_options_addGatheringPress",
		shareCommunityPress: "community_options_shareCommunityPress",
		pricingPress: "community_options_pricingPress",
		billingPress: "community_options_billingPress",
		seeMembersPress: "community_options_seeMembersPress",
		deleteCommunity: {
			initiate: "community_options_deleteCommunity_initiate",
			cancel: "community_options_deleteCommunity_cancel",
			confirm: "community_options_deleteCommunity_confirm",
		},
		reportCommunity: {
			initiate: "community_options_reportCommunity_initiate",
			cancel: "community_options_reportCommunity_cancel",
			confirm: "community_options_reportCommunity_confirm",
		},
		leaveCommunity: {
			initiate: "community_options_leaveCommunity_initiate",
			cancel: "community_options_leaveCommunity_cancel",
			confirm: "community_options_leaveCommunity_confirm",
		},
	},
	membersPage: {
		bottomPremiumPress: "community_membersPage_bottomPremiumPress",
		bottomSharePress: "community_membersPage_bottomSharePress",
		requestJoinPremiumPress: "community_membersPage_requestJoinPremiumPress",
		removeMember: {
			initiate: "community_membersPage_removeMember_initiate",
			cancel: "community_membersPage_removeMember_cancel",
			confirm: "community_membersPage_removeMember_confirm",
		},
		removeAdmin: {
			initiate: "community_membersPage_removeAdmin_initiate",
			cancel: "community_membersPage_removeAdmin_cancel",
			confirm: "community_membersPage_removeAdmin_confirm",
		},
		addAdmin: {
			initiate: "community_membersPage_addAdmin_initiate",
			cancel: "community_membersPage_addAdmin_cancel",
			confirm: "community_membersPage_addAdmin_confirm",
		},
	},
	pricing: {
		open: "community_pricing_open",
		close: "community_pricing_close",
		enterprisePress: "community_pricing_enterprisePress",
		planPress: "community_pricing_planPress",
		checkoutInitiate: "community_pricing_checkoutInitiate",
	},
};
export const contentFilterBox = {
	unlock: {
		initiate: "contentFilterBox_unlock_initiate",
		acknowledge: "contentFilterBox_unlock_acknowledge",
	},
};

export const payment = {
	submit: "payment_submit",
};

export const cache = {
	clear: {
		prompt: "cache_clear_prompt",
		confirm: "cache_clear_confirm",
		cancel: "cache_clear_cancel",
		noPromptClear: "cache_noPromptClear",
	},
};

export const deepLink = {
	appOpen: "deepLink_appOpen",
};

/**
 * Suggested to use following properties: {
 *  input: string
 * }
 */
export const focusField = "focusField";

/**
 * Suggested to use following properties: {
 *  input: string
 * }
 */
export const invalidField = "invalidField";

export const suggestCreateGathering = {
	skip: "suggestCreateGathering_skip",
	shuffle: "suggestCreateGathering_shuffle",
	join: "suggestCreateGathering_join",
};

export const homeEmpty = {
	joinExisting: "homeEmpty_joinExisting",
};

export const feed = {
	photoInteract: "feed_photoInteract",
	defaultCreatePromotionInteract: "feed_defaultCreatePromotionInteract",
};
